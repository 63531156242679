import {PureComponent} from 'react';
import { connect } from 'react-redux';

import ProductTimer from 'Component/ProductTimer/ProductTimer.component';

import {
    ProductTimerContainerFunctions,
    ProductTimerContainerProps,
    ProductTimerContainerState,
} from './ProductTimer.type';

/** @namespace Steinkrueger/Component/ProductTimer/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Steinkrueger/Component/ProductTimer/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Steinkrueger/Component/ProductTimer/Container */
export class ProductTimerContainer extends PureComponent<
ProductTimerContainerProps,
ProductTimerContainerState,
ProductTimerContainerFunctions> {
    containerFunctions: ProductTimerContainerFunctions = {
        hideTimer: this.hideTimer.bind(this),
    };

    __construct(): void {
        this.state = {
            isTimerVisible: true,
        };
    }

    componentDidUpdate(prevProps: ProductTimerContainerProps): void {
        const { time } = this.props;
        const { time: prevTime } = prevProps;

        if (time && time !== prevTime) {
            this.setState({isTimerVisible: true});
        }
    }

    containerProps() {
        const {
            time,
        } = this.props;

        return {
            time,
        };
    }

    hideTimer() {
        this.setState({
            isTimerVisible: false,
        });
    }

    render() {
        const { isTimerVisible } = this.state;

        return (
            isTimerVisible && (
                <ProductTimer
                  { ...this.containerProps() }
                  { ...this.containerFunctions }
                />
            )
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTimerContainer);
