import {
    ProductListComponent as SourceProductListComponent,
} from 'SourceComponent/ProductList/ProductList.component';
import {ReactElement} from 'Type/Common.type';
import {isSignedIn} from 'Util/Auth/IsSignedIn';
import {setLoadedFlag} from 'Util/Request/LowPriorityLoad';

/** @namespace Steinkrueger/Component/ProductList/Component */
export class ProductListComponent extends SourceProductListComponent {
    renderNoProductsForLoggedInUser(): ReactElement {
        return (
            <div block="ProductList">
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                    <h2>{ __('Please contact us if you are interested in this article') }</h2>
                </div>
            </div>
        );
    }

    render(): ReactElement {
        const {
            totalPages,
            isLoading,
            mix,
            selectedFilters,
        } = this.props;

        const loggedIn = isSignedIn();

        if (!isLoading && totalPages === 0) {
            setLoadedFlag();

            if (loggedIn && Object.keys(selectedFilters).length === 0) {
                return this.renderNoProductsForLoggedInUser();
            }

            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default ProductListComponent;
