/* eslint-disable max-len */

import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

/** @namespace Steinkrueger/Component/ProductChevronIcon/Component */
export class ProductChevronIconComponent extends PureComponent {
    render(): ReactElement {
        return (
            <svg
              block="ProductChevronIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
            >
                <path d="M1 1L6 6L1 11" stroke="#003E90" strokeWidth="2" strokeLinecap="round" />
            </svg>
        );
    }
}

export default ProductChevronIconComponent;
